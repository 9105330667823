import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

// Importing all images from the toadtracker_resources folder
import logo from '../toadtracker_resources/logo.png';  // Importing the ToadTracker logo
import poster from '../toadtracker_resources/poster.jfif';
import ausCaneToadMap from '../toadtracker_resources/aus cane toad map.jfif';
import drone from '../toadtracker_resources/drone.png';
import spectrogram from '../toadtracker_resources/spectrogram.png';
import pod from '../toadtracker_resources/pod.png';
import mapping from '../toadtracker_resources/mapping.png';
import toads from '../toadtracker_resources/toads.jfif';

const ToadTracker = () => {
    useDocTitle('Pertechno | ToadTracker - Autonomous Identification & Localisation');

    return (
        <>
            <NavBar />

            <div id="toadtracker" className="flex flex-col items-center w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-20 text-center" data-aos="fade-up">
                    <img src={logo} alt="ToadTracker Logo" className="mx-auto w-48 mb-8" />
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">ToadTracker</h1>
                    <div className="my-8">
                        <img src={poster} alt="ToadTracker Poster" className="mx-auto w-full max-w-4xl shadow-lg rounded-lg" />  {/* Large Poster */}
                    </div>
                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left" data-aos="fade-up" data-aos-delay="200">
                    <p className="text-lg text-gray-700 leading-relaxed">
                        ToadTracker is an innovative system designed to help monitor and manage the spread of Australia's invasive Cane Toad population. Using AI-based acoustic identification techniques and a specialized microphone array, ToadTracker can detect and locate cane toad calls at night from an altitude of about 50 meters, enabling real-time tracking of these invasive species.
                    </p>
                    <p className="text-lg text-gray-700 leading-relaxed mt-4">
                        The system includes a pod mountable on commercially available drones, equipped with a 6-microphone array, GPS, and AI for sound recognition. The pod is capable of identifying cane toads with 91% accuracy and can localize their calls with an accuracy of ±1.1m. The gathered data is then used to map cane toad hotspots, helping control teams target their efforts more effectively.
                    </p>
                    <div className="mb-4 md:mb-8 flex justify-center">
                        <Link to="/contact" className="shine-effect inline-flex items-center justify-center px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                            Enquire
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                    </div>

                </div>
            </div>

            <div className="my-12 text-center" data-aos="fade-up">
                <h2 className="font-bold text-blue-900 uppercase text-2xl mb-4">Mapping Interface Demo</h2>
                <div className="relative mx-auto max-w-2xl" style={{ paddingBottom: '19.8%', position: 'relative', height: 0 }}>
                    <iframe
                        className="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube.com/embed/zrRIWF4dWNI"
                        title="ToadTracker Mapping Interface"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>

            <div className="my-12 text-center" data-aos="fade-up">
                <h2 className="font-bold text-blue-900 uppercase text-2xl mb-6">ToadTracker Image Gallery</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="flex flex-col items-center justify-center h-full">
                        <img src={ausCaneToadMap} alt="Cane Toad Spread Map" className="w-full max-w-xs shadow-lg rounded-lg" />
                        <p className="mt-2 text-gray-600 text-center">Australia's Cane Toad Spread Map</p>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full">
                        <img src={drone} alt="Drone" className="w-full max-w-xs shadow-lg rounded-lg" />
                        <p className="mt-2 text-gray-600 text-center">Drone Design for ToadTracker Pod</p>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full">
                        <img src={spectrogram} alt="Spectrogram" className="w-full max-w-xs shadow-lg rounded-lg" />
                        <p className="mt-2 text-gray-600 text-center">Spectrogram Analysis of Cane Toad Calls</p>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full">
                        <img src={pod} alt="ToadTracker Pod" className="w-full max-w-xs shadow-lg rounded-lg" />
                        <p className="mt-2 text-gray-600 text-center">ToadTracker Pod Design</p>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full">
                        <img src={mapping} alt="Mapping" className="w-full max-w-xs shadow-lg rounded-lg" />
                        <p className="mt-2 text-gray-600 text-center">Mapping of Cane Toad Hotspots</p>
                    </div>
                    <div className="flex flex-col items-center justify-center h-full">
                        <img src={toads} alt="Cane Toads" className="w-full max-w-xs shadow-lg rounded-lg" />
                        <p className="mt-2 text-gray-600 text-center">Captured Cane Toads</p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default ToadTracker;
