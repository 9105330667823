import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const AIHardware = () => {
    useDocTitle('Pertechno | Advanced AI Hardware Solutions');

    return (
        <>
            <NavBar />

            <div id="aihardware" className="flex flex-col items-center w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-20 text-center" data-aos="fade-up">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">Advanced AI Hardware Solutions</h1>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        At Pertechno, we provide cutting-edge AI hardware solutions tailored to your specific needs. Whether you're building AI-powered applications or need advanced hardware for deep learning, we have the expertise and technology to help you succeed.
                        We work closely with leading suppliers like Dell and wholesalers of PC parts to deliver robust, scalable, and high-performance hardware solutions.
                    </p>
                    <div className="mb-4 md:mb-8 flex justify-center">
                        <Link to="/contact" className="shine-effect inline-flex items-center justify-center px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                            Enquire
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                    </div>
                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left" data-aos="fade-up" data-aos-delay="200">
                    <h2 className="font-bold text-blue-900 uppercase text-2xl mb-4">Our Capabilities</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        Our AI hardware solutions are designed to meet the demanding requirements of modern AI applications:
                    </p>
                    <ul className="list-disc list-inside text-lg text-gray-700 mt-4">
                        <li><strong>Custom AI Hardware Design:</strong> We design and build custom AI hardware platforms tailored to your specific application requirements, with the option to use high-quality components from Dell and other leading wholesalers.</li>
                        <li><strong>High-Performance GPUs:</strong> Our solutions feature the latest high-performance GPUs optimized for deep learning and AI workloads, seamlessly integrated with TensorFlow or PyTorch development platforms.</li>
                        <li><strong>AI-Optimized Servers:</strong> We provide AI-optimized servers that offer exceptional performance, scalability, and reliability for AI training and inference, all running on a stable Ubuntu Server environment.</li>
                        <li><strong>Edge AI Solutions:</strong> Our edge AI hardware is designed to bring powerful AI capabilities to the edge, enabling real-time processing and decision-making.</li>
                        <li><strong>Secure Data Integration:</strong> We seamlessly integrate with your file servers and provide secure databases with hotswapping and backup systems, ensuring your data is always protected and accessible.</li>
                        <li><strong>Turnkey Solutions:</strong> We offer turnkey AI hardware solutions, including installation, configuration, and ongoing support.</li>
                    </ul>
                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center my-12" data-aos="fade-up" data-aos-delay="600">
                    <h2 className="font-bold text-blue-900 uppercase text-2xl mb-4">Why Choose Our AI Hardware Solutions?</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        With Pertechno, you gain access to the latest AI hardware technologies, backed by our commitment to quality and customer satisfaction. Our solutions are tailored to your specific needs, ensuring seamless integration with your existing infrastructure. We work closely with you to understand your needs and deliver solutions that exceed expectations.
                    </p>
                    <div className="mb-4 md:mb-8 flex justify-center">
                        <Link to="/contact" className="shine-effect inline-flex items-center justify-center px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                            Get a Quote
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default AIHardware;
