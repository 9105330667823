import React from 'react';
import { HashLink } from 'react-router-hash-link';

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; // Adjust this value as needed
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const NavLinks = () => {
    return (
        <>
            <HashLink scroll={scrollWithOffset} className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#about">
                About
            </HashLink>
            <HashLink scroll={scrollWithOffset} className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#services">
                Services
            </HashLink>
            <HashLink scroll={scrollWithOffset} className="px-4 font-extrabold text-gray-500 hover:text-blue-900" smooth to="/#portfolio">
                Portfolio
            </HashLink>
            <HashLink
                className="text-white bg-gradient-to-r from-blue-600 to-blue-900 shine-effect transform transition-all duration-300 ease-in-out inline-flex items-center justify-center w-auto px-8 py-3 shadow-2xl rounded-2xl hover:scale-105 hover:shadow-blue-500/50"
                smooth
                to="/get-demo#demo"
            >
                <span className="mr-2">💬</span> Get in Touch
            </HashLink>
        </>
    );
}

export default NavLinks;
