import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

const CNCService = () => {
    useDocTitle('Pertechno | Precision CNC Machining Services');

    return (
        <>
            <NavBar />

            <div id="cncservice" className="flex flex-col items-center w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-20 text-center" data-aos="fade-up">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">Precision CNC Machining Services</h1>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        Our CNC Machining Service offers precision cutting and fabrication for a variety of materials, including wood, foam, aluminum, and stainless steel. Whether you're prototyping or require custom parts for your project, our in-house capabilities ensure high-quality results with fast turnaround times.
                    </p>
                    <div className="mb-4 md:mb-8 flex justify-center">
                        <Link to="/contact" className="shine-effect inline-flex items-center justify-center px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                            Enquire
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                    </div>
                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left" data-aos="fade-up" data-aos-delay="200">
                    <h2 className="font-bold text-blue-900 uppercase text-2xl mb-4">Our Capabilities</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        We specialize in delivering custom CNC solutions tailored to your specific needs:
                    </p>
                    <ul className="list-disc list-inside text-lg text-gray-700 mt-4">
                        <li><strong>Prototyping & Custom Parts:</strong> Rapid prototyping and custom part production for a variety of industries, including aerospace, automotive, and consumer goods.</li>
                        <li><strong>Multi-Material Expertise:</strong> Capabilities in machining wood, foam, aluminum, stainless steel, and other materials to high precision.</li>
                        <li><strong>Advanced CNC Technology:</strong> State-of-the-art CNC machines equipped with the latest technology for precise and efficient production.</li>
                        <li><strong>Quick Turnaround:</strong> Fast and reliable service to meet your project deadlines, without compromising on quality.</li>
                        <li><strong>Consultation & Design:</strong> Expert consultation services to help you design and plan your project, ensuring the best outcomes.</li>
                    </ul>
                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center my-12" data-aos="fade-up" data-aos-delay="600">
                    <h2 className="font-bold text-blue-900 uppercase text-2xl mb-4">Why Choose Us?</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        With our extensive experience in CNC machining and dedication to quality, we are the perfect partner for your next project. We pride ourselves on delivering exceptional results, on time, and within budget.
                    </p>
                    <div className="mb-4 md:mb-8 flex justify-center">
                        <Link to="/contact" className="shine-effect inline-flex items-center justify-center px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                            Get a Quote
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default CNCService;
