import React from 'react';
import { Link } from 'react-router-dom';
import aiLogo from '../images/ai-logo.svg'; 
import toadTrackerLogo from '../images/toadtracker-logo.svg';
import colesLogo from '../images/coles-logo.svg';
import bunningsLogo from '../images/bunnings-logo.svg';
import consultationLogo from '../images/consultation-logo.svg';
import trainingLogo from '../images/training-logo.svg';
import cncLogo from '../images/cnc-logo.svg';
import tramSuccessLogo from '../images/tram-success-logo.svg';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 py-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Products & Services</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
                        
                        {/* Custom AI Assistants Tile */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold mb-2 text-lg md:text-xl text-center h-14">Custom AI Assistants</h4>
                                <img src={aiLogo} alt="Custom AI Assistants Logo" className="mx-auto mb-2 h-10 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                                <p className="text-md font-medium leading-5 h-24 overflow-hidden">
                                    Enhance customer engagement and boost internal productivity with AI-powered assistants tailored to your data. Our solutions integrate seamlessly with your systems to provide accurate, context-aware information.
                                </p>
                                <div className="flex justify-center mt-2">
                                    <Link to="/CustomAssistants" target="_blank" rel="noopener noreferrer" className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-4 py-2 text-lg shadow-xl rounded-xl">
                                        Find out more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* Toad Tracker Tile */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold mb-2 text-lg md:text-xl text-center h-14">Toad Tracker</h4>
                                <img src={toadTrackerLogo} alt="Toad Tracker Logo" className="mx-auto mb-2 h-10 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                                <p className="text-md font-medium leading-5 h-24 overflow-hidden">
                                    Track and manage Australia's Cane Toad invasion with advanced AI acoustic identification and drone-mounted technology, providing real-time data to control teams on the ground.
                                </p>
                                <div className="flex justify-center mt-2">
                                    <Link to="/toadtracker" target="_blank" rel="noopener noreferrer" className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-4 py-2 text-lg shadow-xl rounded-xl">
                                        Visit ToadTracker
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* Coles Item Locator Tile */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 group min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold mb-2 text-lg md:text-xl text-center h-14">Coles Item Locator</h4>
                                <img src={colesLogo} alt="Coles Logo" className="mx-auto mb-2 h-10 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                                <p className="text-md font-medium leading-5 h-24 overflow-hidden">
                                    Quickly find items in Coles supermarkets using AI-powered semantic search and speech recognition, making your shopping experience faster and more efficient.
                                </p>
                                <div className="flex justify-center mt-2">
                                    <a href="https://coleslocator.com" target="_blank" rel="noopener noreferrer" className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-4 py-2 text-lg shadow-xl rounded-xl">
                                        Visit Coles Locator
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* Bunnings Item Locator Tile */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold mb-2 text-lg md:text-xl text-center h-14">Bunnings Item Locator</h4>
                                <img src={bunningsLogo} alt="Bunnings Logo" className="mx-auto mb-2 h-10 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                                <p className="text-md font-medium leading-5 h-24 overflow-hidden">
                                    Use our tool to quickly locate specific items in Bunnings stores, saving time and improving the efficiency of your shopping trips.
                                </p>
                                <div className="flex justify-center mt-2">
                                    <a href="https://bunningslocator.com" target="_blank" rel="noopener noreferrer" className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-4 py-2 text-lg shadow-xl rounded-xl">
                                        Visit Bunnings Locator
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* AI Consultations Tile */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold mb-2 text-lg md:text-xl text-center h-14">AI Consultations</h4>
                                <img src={consultationLogo} alt="AI Consultation Logo" className="mx-auto mb-2 h-10 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                                <p className="text-md font-medium leading-5 h-24 overflow-hidden">
                                    Gain valuable insights and a clear AI roadmap with our expert consultation services, tailored to meet your business's unique needs and challenges.
                                </p>
                                <div className="flex justify-center mt-2">
                                    <Link to="/aiconsultations" target="_blank" rel="noopener noreferrer" className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-4 py-2 text-lg shadow-xl rounded-xl">
                                        Find out more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* AI Training Rigs & Servers Tile */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold mb-2 text-lg md:text-xl text-center h-14">AI Training Rigs & Servers</h4>
                                <img src={trainingLogo} alt="AI Training Logo" className="mx-auto mb-2 h-10 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                                <p className="text-md font-medium leading-5 h-24 overflow-hidden">
                                    Build and configure high-performance AI rigs and servers, with expert guidance in hardware selection and software setup for your specific needs.
                                </p>
                                <div className="flex justify-center mt-2">
                                    <Link to="/aihardware" target="_blank" rel="noopener noreferrer" className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-4 py-2 text-lg shadow-xl rounded-xl">
                                        Find out more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* CNC Machining Tile */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold mb-2 text-lg md:text-xl text-center h-14">CNC Machining</h4>
                                <img src={cncLogo} alt="CNC Logo" className="mx-auto mb-2 h-10 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                                <p className="text-md font-medium leading-5 h-24 overflow-hidden">
                                    Precision cutting and fabrication services for various materials, tailored to meet your specific project requirements with fast turnaround times.
                                </p>
                                <div className="flex justify-center mt-2">
                                    <Link to="/cncservice" target="_blank" rel="noopener noreferrer" className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-4 py-2 text-lg shadow-xl rounded-xl">
                                        Find out more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {/* Melbourne Tram Success Tile */}
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 group h-auto">
                            <div className="m-2 text-justify text-sm">
                                <h4 className="font-semibold mb-2 text-lg md:text-xl text-center h-14">Melbourne Tram Success</h4>
                                <img src={tramSuccessLogo} alt="Tram Success Logo" className="mx-auto mb-2 h-10 transition-transform duration-300 ease-in-out group-hover:scale-150"/>
                                <p className="text-md font-medium leading-5 h-24 overflow-hidden">
                                    Discover the history and innovations of Melbourne's tram system in "Trams of Melbourne." Explore its achievements and get your copy today!
                                </p>
                                <div className="flex justify-center mt-2">
                                    <a href="https://melbournetramsuccess.com" target="_blank" rel="noopener noreferrer" className="text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:scale-105 transform transition-all duration-300 inline-flex items-center justify-center w-full px-4 py-2 text-lg shadow-xl rounded-xl">
                                        Visit Melbourne Tram Success
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;
