import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';

// Importing images for the Custom Assistants section
import ancaImage1 from '../customassistants_resources/llm1.PNG';
import ancaImage2 from '../customassistants_resources/llm2.PNG';
import ancaImage3 from '../customassistants_resources/llm3.PNG';

const CustomAssistants = () => {
    useDocTitle('Pertechno | Revolutionizing Support with Custom LLMs');

    return (
        <>
            <NavBar />

            <div id="customassistants" className="flex flex-col items-center w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-20 text-center" data-aos="fade-up">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">Revolutionize Your Business with Custom LLMs</h1>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        Custom Large Language Models (LLMs) are transforming the way businesses handle customer queries, internal code and best practices, and user manual retrieval. By harnessing the power of AI, our custom LLMs can revolutionize how your customers automatically search user manuals, how your team use custom coding languages, and how you query internal documents and best practices, all while delivering significant cost savings.
                        Multiple LLMs can be trained for your company depending on the application and deployed to the same interface.
                    </p>
                    <div className="mb-4 md:mb-8 flex justify-center">
                        <Link to="/contact" className="shine-effect inline-flex items-center justify-center px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                            Enquire
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                    </div>

                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left" data-aos="fade-up" data-aos-delay="200">
                    <h2 className="font-bold text-blue-900 uppercase text-2xl mb-4">Key Benefits & Capabilities</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        Our custom LLMs provide a range of powerful features that can redefine your business processes and customer journey:
                    </p>
                    <ul className="list-disc list-inside text-lg text-gray-700 mt-4">
                        <li><strong>Effortless User Manual Navigation:</strong> Empower customers and operators to quickly locate precise answers in user manuals using natural language queries. Enhance accuracy and significantly reduce support response times, elevating customer satisfaction.</li>
                        <li><strong>Advanced Internal Training for Custom Code Languages:</strong> Equip your team with the power of LLMs that are fine-tuned to understand proprietary coding languages and tools unique to your business. Boost process efficiency and ensure consistency across all internal operations.</li>
                        <li><strong>Streamlined Access to Internal Knowledge:</strong> Provide your employees with the ability to effortlessly query internal documents, best practices, codebases, and databases. Facilitate faster, more informed decision-making across your organization.</li>
                        <li><strong>Substantial Cost Reduction:</strong> Automate routine support tasks and diminish the reliance on large customer and aftersales support teams. Our custom LLMs handle common inquiries, dramatically cutting down on support costs while maintaining high service standards.</li>
                        <li><strong>Reliable and Secure Hosting Solutions:</strong> Ensure your custom LLMs are always operational, protected, and readily accessible with our subscription-based, cloud-hosted secure solutions. Experience peace of mind with continuous, worry-free access.</li>
                        <li><strong>Enhanced Security with Proxy Integration:</strong> Safeguard your LLMs using proxy-based security that integrates seamlessly with your company’s SSL certificates. Control access and ensure only authorized users can interact with specific LLMs.</li>
                        <li><strong>Scalable Custom Solutions:</strong> Our LLMs are tailored to meet your specific needs and can easily scale as your business grows, ensuring you always have the right tools to stay ahead of the competition.</li>
                        <li><strong>Expert Support and Continuous Improvement:</strong> Benefit from our dedicated support and ongoing enhancements to your LLMs, ensuring they evolve with your business and continue to deliver top-tier performance.</li>
                    </ul>

                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center my-12" data-aos="fade-up" data-aos-delay="400">
                    <h2 className="font-bold text-blue-900 uppercase text-2xl mb-6">Example Deployment</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="flex flex-col items-center justify-center h-full">
                            <img src={ancaImage1} alt="ANCA Custom Assistant Image 1" className="w-full max-w-xs shadow-lg rounded-lg" />
                            <p className="mt-2 text-gray-600 text-center">Custom LLM Integration Interface</p>
                        </div>
                        <div className="flex flex-col items-center justify-center h-full">
                            <img src={ancaImage2} alt="ANCA Custom Assistant Image 2" className="w-full max-w-xs shadow-lg rounded-lg" />
                            <p className="mt-2 text-gray-600 text-center">Custom LLM Mobile Chatbot</p>
                        </div>
                        <div className="flex flex-col items-center justify-center h-full">
                            <img src={ancaImage3} alt="ANCA Custom Assistant Image 3" className="w-full max-w-xs shadow-lg rounded-lg" />
                            <p className="mt-2 text-gray-600 text-center">Custom LLM Website Chatbot</p>
                        </div>'
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default CustomAssistants;
