import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import eventImage from '../images/people-taking-part-business-event.jpg'; // Importing the image

const AIConsultations = () => {
    useDocTitle('Pertechno | Expert AI Consultations & Workshops');

    return (
        <>
            <NavBar />

            <div id="aiconsultations" className="flex flex-col items-center w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto px-4 lg:px-20 text-center" data-aos="fade-up">
                    <h1 className="mb-5 md:text-5xl text-3xl font-bold text-blue-900">Expert AI Consultations & Workshops</h1>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        At Pertechno, we specialize in delivering comprehensive AI consultation services tailored to meet the specific needs of your business. Our expert team of computer science engineers has extensive experience across various industries, allowing us to provide valuable insights and strategies that will help your business integrate AI effectively.
                    </p>
                    <div className="mb-4 md:mb-8 flex flex-col items-center">
                        <Link to="/contact" className="shine-effect inline-flex items-center justify-center px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                            Enquire
                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </Link>
                        <img src={eventImage} alt="Business Event" className="mt-4 w-96 rounded-lg shadow-lg" />
                    </div>
                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left" data-aos="fade-up" data-aos-delay="200">
                    <h2 className="font-bold text-blue-900 uppercase text-2xl mb-4">Interactive AI Presentations</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        We offer a 2-hour interactive presentation on the basics of AI and how it can be integrated into your business operations. Our sessions are designed to be engaging, informative, and tailored to your industry. The presentation will cover essential AI concepts, real-world applications, and how AI can drive innovation and efficiency in your business.
                    </p>
                    <p className="text-lg text-gray-700 leading-relaxed mt-4">
                        This presentation is suitable for all departments, as AI integration requires collaboration across the entire organization. It's not just for software and IT teams—marketing, strategy, management, and other departments must also be AI-literate in today's rapidly evolving landscape.
                    </p>
                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left mt-12" data-aos="fade-up" data-aos-delay="400">
                    <h2 className="font-bold text-blue-900 uppercase text-2xl mb-4">AI Implementation Strategy</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        Following an initial presentation, we can work closely with your business to develop a tailored AI implementation strategy. Our approach includes identifying key areas where AI can be integrated to enhance operations, improve decision-making, and drive business growth. We focus on practical, scalable solutions that align with your long-term goals.
                    </p>
                    <p className="text-lg text-gray-700 leading-relaxed mt-4">
                        Our team of qualified computer science engineers will provide ongoing support throughout the implementation process, ensuring that your AI initiatives are successfully executed and deliver measurable results.
                    </p>
                </div>

                <div className="container mx-auto px-4 lg:px-20 text-center lg:text-left mt-12" data-aos="fade-up" data-aos-delay="600">
                    <h2 className="font-bold text-blue-900 uppercase text-2xl mb-4">Workshops & Training</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        We also offer hands-on workshops designed to equip your teams with the skills and knowledge needed to work with AI technologies. Our workshops can accommodate groups of up to 10 staff members, providing a focused, interactive learning experience. For larger groups, we can deliver presentations to your entire organization, ensuring that everyone is on the same page when it comes to AI integration.
                    </p>
                    <p className="text-lg text-gray-700 leading-relaxed mt-4">
                        Our goal is to help your entire organization become AI-literate, fostering a culture of innovation and collaboration that will drive your business forward in the AI era.
                    </p>
                </div>

                <div className="mb-12 md:mb-16 flex justify-center mt-12">
                    <Link to="/contact" className="shine-effect inline-flex items-center justify-center px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                        Schedule a Consultation
                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                        </svg>
                    </Link>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default AIConsultations;
